import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Contact from "../Contact"
import style from "./Footer.module.css"

const ContactInfo = () =>
    <div className="uk-width-2-5@m uk-flex-first@m">
        <h3>The G-DIP Project</h3>
        <p>Enabling decisions through transparency.</p>
        <p>
            <small className="uk-display-block">Powered by Solera Advisors</small>
            <span className="uk-h5 uk-text-bold">
                Chicago | Toronto | London | Kiel
            </span>
        </p>
        <a href="https://twitter.com/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="twitter" />
        <a href="https://www.facebook.com//" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="facebook" />
        <a href="https://www.linkedin.com/company/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="linkedin" />
    </div>

const Footer = () => {
    const { pathname } = useLocation()
    return (
        <section className={`${style.footerContent} footer uk-section uk-section-large uk-section-muted uk-padding-remove-bottom`}>
            <div className="uk-container uk-container-small">
                {pathname !== '/contact-us' && <h2 className="uk-text-center uk-margin-large bottom">Contact us</h2>}
                <div className="uk-grid uk-grid-large" data-uk-grid >
                    <ContactInfo />
                    <div className="uk-width-expand uk-flex-first">
                        <Contact />
                    </div>
                </div>
            </div>
            <div className="uk-container uk-container-xsmall uk-padding-remove-bottom uk-margin-remove-bottom">
                <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
                    {pathname === '/' ?
                    <small className="uk-text-muted uk-display-block uk-margin-small-bottom uk-margin-remove-top">
                        <sup>1</sup> These are forward assessments, not forecasts. These are assessments of ingredients, not commodities. To arrive at your local market equivalent, add appropriate trade levies as well as domestic processing and packing costs.
                    </small>
                    : null}
                    <small className="uk-text-muted">&copy; 2018-2021 <a href="https://globalmilk.net">The G-DIP Project.</a> All Rights Reserved.</small>
                </div>
            </div>
        </section>
    )
}
export default Footer;
