import { Link } from "gatsby";
import React from "react";
import { useLocation } from "@reach/router";
import style from "./Navigation.module.css";
import logo from "../../images/g-dip-logo.png";

const OffCanvas = () => {
    const { pathname } = useLocation()
    return (
        <div className={`uk-navbar-container uk-navbar-transparent uk-hidden@m`}
            data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small">
            <nav className={`${style.mobileNav}`} data-uk-navbar>
                <div className={`uk-navbar-center`}>
                    <div className={`${style.Logo} uk-navbar-item uk-logo`}>
                        <Link to={`/`}><img src={logo} alt="Logo" /></Link>
                    </div>
                </div>
                <div className="uk-navbar-left">
                    <button
                        type="button"
                        data-uk-toggle="target: #mobileNav"
                        className="uk-button-text uk-navbar-toggle uk-navbar-item"
                        data-uk-navbar-toggle-icon />
                </div>
            </nav>
            <div id="mobileNav" data-uk-offcanvas="mode: push;selClose: .active">
                <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-text-center">
                    <button
                        className="uk-position-top-left uk-position-small uk-offcanvas-close active"
                        type="button"
                        data-uk-close />
                    <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical uk-nav-parent-icon uk-width-1-1" data-uk-nav>
                        <li className="uk-margin-small">
                            <Link className={pathname === "/" ? "active" : ""} to={`/`}>Home</Link>
                        </li>
                        <li className="uk-margin-small">
                            <Link className={pathname === "/our-method" ? "active" : ""} to={`/our-method`}>Methods</Link>
                        </li>
                        <li className="uk-margin-small">
                            <Link className={pathname === "/exploration" ? "active" : ""} to={`/exploration`}>Exploration</Link>
                        </li>
                        {/*
                        <li className="uk-margin-small">
                            <Link className={pathname === "/global-exchange" ? "active" : ""} to={`/global-exchange`}>Global Exchange</Link>
                        </li>
                        */}
                        <li className="uk-margin-small">
                            <Link className={pathname === "/contact-us" ? "active" : ""} to={`/contact-us`}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
const Navigation = ({...props}) => {
    return (
        <>
        <div className={`${style.nav}`} data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small">
            <div className="uk-container uk-container-small uk-padding-remove">
                <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                    <div className="uk-navbar-left uk-padding-remove-left">
                        <div className={`${style.Logo} uk-navbar-item uk-logo`}>
                            <Link to={`/`}><img src={logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav">
                            <li><Link to={`/our-method`}>Methods</Link></li>
                            <li><Link to={`/exploration`}>Exploration</Link></li>
                            {/* <li><Link to={`/global-exchange`}>Global Exchange</Link></li> */}
                            <li><Link to={`/contact-us`}>Contact</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        <OffCanvas />
        </>
    )
}

export default Navigation;
